<template>
  <div>
    <b-form-group
      :label="`Título del ${$getVisibleNames(
        'evaluations2.testdescriptor',
        false,
        'Descriptor'
      )}`"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="
          `Editar Título del ${$getVisibleNames(
            'evaluations2.testdescriptor',
            false,
            'Descriptor'
          )}`
        "
        class="descriptor-form-description rich-text-content mb-2"
        v-html="$v.test_descriptor.title.$model"
        :class="{ 'warning-border': $v.test_descriptor.$anyError }"
        @click="
          $bvModal.show(
            `edit-test_descriptor-title-modal-${test_descriptor.id}`
          )
        "
      ></div>
      <div v-if="$v.test_descriptor.$anyError" style="color: red">
        Este campo es obligatorio.
      </div>
      <b-modal
        :id="`edit-test_descriptor-title-modal-${test_descriptor.id}`"
        :title="`Editar Título del ${$getVisibleNames(
          'evaluations2.testdescriptor',
          false,
          'Descriptor'
        )}`"
        size="lg"
        hide-footer
        ignore-enforce-focus-selector="#sidebar-right"
      >
        <NewRichTextEditor
          :Object="test_descriptor"
          :Text="test_descriptor.title"
          @save="patchDescriptorTitle"
          @close="
            $bvModal.hide(
              `edit-test_descriptor-title-modal-${test_descriptor.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`Descripción del ${$getVisibleNames(
        'evaluations2.testdescriptor',
        false,
        'Descriptor'
      )}`"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="
          `Editar Descripción del ${$getVisibleNames(
            'evaluations2.testdescriptor',
            false,
            'Descriptor'
          )}`
        "
        class="descriptor-form-description rich-text-content mb-2"
        v-html="$v.test_descriptor.description.$model"
        @click="
          $bvModal.show(
            `edit-test_descriptor-description-modal-${test_descriptor.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-test_descriptor-description-modal-${test_descriptor.id}`"
        :title="`Editar Descripción del ${$getVisibleNames(
          'evaluations2.testdescriptor',
          false,
          'Descriptor'
        )}`"
        size="xl"
        hide-footer
        ignore-enforce-focus-selector="#sidebar-right"
      >
        <NewRichTextEditor
          :Object="test_descriptor"
          :Text="test_descriptor.description"
          :permit_blank="true"
          @save="patchDescriptorDescription"
          @close="
            $bvModal.hide(
              `edit-test_descriptor-description-modal-${test_descriptor.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Visible para Estudiantes -->
    <b-form-group
      label-for="input-visible-evaluatees"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <template #label>
        <div class="block-title-container">
          <div>Mostrar a Evaluados</div>
          <div class="ml-2">
            <InfoTooltip
              :tooltip_text="`Si se selecciona esta opción el contenido estará visible para el Evaluado`"
            ></InfoTooltip>
          </div>
        </div>
      </template>
      <b-form-checkbox
        class="mt-2"
        v-model="test_descriptor.visible_by_evaluatees"
        size="md"
      >
      </b-form-checkbox>
    </b-form-group>
    <div class="col" style="text-align: right">
      <b-button class="mr-1" size="sm" @click="saveTestDescription">
        Guardar
      </b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TestDescriptorForm",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    test_id: {
      type: Number,
      required: true,
    },
    position_number: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
    },
    testDescriptor: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          order: this.order,
          test: this.test_id,
          position: this.position_number,
          visible_by_evaluatees: true,
        };
      },
    },
  },
  data() {
    return {
      test_descriptor: { ...this.testDescriptor },
    };
  },
  validations: {
    test_descriptor: {
      title: { required },
      description: {},
    },
  },
  computed: {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.test_descriptor[name];
      return $dirty ? !$error : null;
    },
    patchDescriptorTitle(object, text) {
      this.test_descriptor.title = text;
    },
    patchDescriptorDescription(object, text) {
      this.test_descriptor.description = text;
    },
    saveTestDescription() {
      this.$v.test_descriptor.$touch();
      if (this.$v.test_descriptor.$anyError) {
        return;
      }
      if (isNaN(this.test_descriptor.id)) this.createTestDescriptor();
      else this.updateTestDescriptor();
    },
    createTestDescriptor() {
      this.$store
        .dispatch(names.POST_NEW_TEST_DESCRIPTOR, this.test_descriptor)
        .then((response) => {
          this.test_descriptor = response;
          toast(
            "Se creó el " +
              this.$getVisibleNames(
                "evaluations2.testdescriptor",
                false,
                "Descriptor"
              )
          );
          this.$emit("closeModal");
        });
    },
    updateTestDescriptor() {
      this.$store
        .dispatch(names.UPDATE_NEW_TEST_DESCRIPTOR, this.test_descriptor)
        .then((response) => {
          this.test_descriptor = response;
          toast(
            "Se editó el " +
              this.$getVisibleNames(
                "evaluations2.testdescriptor",
                false,
                "Descriptor"
              )
          );
          this.$emit("closeModal");
        });
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.descriptor-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.descriptor-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
.warning-border {
  border: 1px solid #dc3545 !important;
  color: red;
}
</style>

