var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Título del ${_vm.$getVisibleNames(
      'evaluations2.testdescriptor',
      false,
      'Descriptor'
    )}`,"label-for":"input-name","label-cols":"0","label-cols-sm":"3","label-cols-md":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
        `Editar Título del ${_vm.$getVisibleNames(
          'evaluations2.testdescriptor',
          false,
          'Descriptor'
        )}`
      ),expression:"\n        `Editar Título del ${$getVisibleNames(\n          'evaluations2.testdescriptor',\n          false,\n          'Descriptor'\n        )}`\n      ",modifiers:{"v-secondary":true}}],staticClass:"descriptor-form-description rich-text-content mb-2",class:{ 'warning-border': _vm.$v.test_descriptor.$anyError },domProps:{"innerHTML":_vm._s(_vm.$v.test_descriptor.title.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-test_descriptor-title-modal-${_vm.test_descriptor.id}`
        )}}}),(_vm.$v.test_descriptor.$anyError)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" Este campo es obligatorio. ")]):_vm._e(),_c('b-modal',{attrs:{"id":`edit-test_descriptor-title-modal-${_vm.test_descriptor.id}`,"title":`Editar Título del ${_vm.$getVisibleNames(
        'evaluations2.testdescriptor',
        false,
        'Descriptor'
      )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.test_descriptor,"Text":_vm.test_descriptor.title},on:{"save":_vm.patchDescriptorTitle,"close":function($event){return _vm.$bvModal.hide(
            `edit-test_descriptor-title-modal-${_vm.test_descriptor.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Descripción del ${_vm.$getVisibleNames(
      'evaluations2.testdescriptor',
      false,
      'Descriptor'
    )}`,"label-for":"input-description","label-cols":"0","label-cols-sm":"3","label-cols-md":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
        `Editar Descripción del ${_vm.$getVisibleNames(
          'evaluations2.testdescriptor',
          false,
          'Descriptor'
        )}`
      ),expression:"\n        `Editar Descripción del ${$getVisibleNames(\n          'evaluations2.testdescriptor',\n          false,\n          'Descriptor'\n        )}`\n      ",modifiers:{"v-secondary":true}}],staticClass:"descriptor-form-description rich-text-content mb-2",domProps:{"innerHTML":_vm._s(_vm.$v.test_descriptor.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-test_descriptor-description-modal-${_vm.test_descriptor.id}`
        )}}}),_c('b-modal',{attrs:{"id":`edit-test_descriptor-description-modal-${_vm.test_descriptor.id}`,"title":`Editar Descripción del ${_vm.$getVisibleNames(
        'evaluations2.testdescriptor',
        false,
        'Descriptor'
      )}`,"size":"xl","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.test_descriptor,"Text":_vm.test_descriptor.description,"permit_blank":true},on:{"save":_vm.patchDescriptorDescription,"close":function($event){return _vm.$bvModal.hide(
            `edit-test_descriptor-description-modal-${_vm.test_descriptor.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-feedback"}},[_vm._v("Este campo es opcional.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-visible-evaluatees","label-cols":"0","label-cols-sm":"3","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[_c('div',[_vm._v("Mostrar a Evaluados")]),_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`Si se selecciona esta opción el contenido estará visible para el Evaluado`}})],1)])]},proxy:true}])},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"size":"md"},model:{value:(_vm.test_descriptor.visible_by_evaluatees),callback:function ($$v) {_vm.$set(_vm.test_descriptor, "visible_by_evaluatees", $$v)},expression:"test_descriptor.visible_by_evaluatees"}})],1),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.saveTestDescription}},[_vm._v(" Guardar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }